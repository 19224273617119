const mealTime = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Snack',
  'Dessert',
]

const goals = [
  { text: 'My goal is to lose weight', value: 'g1' },
  // { text: 'My goal is to reduce my blood glucose levels', value: 'g2' },
  // { text: 'My goal is to manage my IBS', value: 'g3' },
  // { text: 'My goal is to increase my fibre', value: 'g4' },
  // { text: 'My goal is to eat appropriately after weight loss surgery', value: 'g5' },
  // { text: 'My goal is to improve my heart health', value: 'g6' },
  // { text: 'My goal is to manage my allergies/intolerance', value: 'g7' },
  { text: 'My goal is to gain weight', value: 'g8' },
  { text: 'My goal is to maintain my weight', value: 'g9' },
]

const frequencies = [
  'Daily',
  'Weekly',
  'Fortnightly',
  'Monthly',
  'Quarterly',
  'Annually',
]

const levels = [
  {
    name: 'Sedentary',
    description: 'Light physical activity associated with typical day-to-day life.',
    male: 1,
    female: 1,
  },
  {
    name: 'Low Active',
    description: 'Walking for around 30-60 minutes at a light to moderate pace, in addition to the light physical activity associated with typical day-to-day life.',
    male: 1.1,
    female: 1.12,
  },
  {
    name: 'Active',
    description: 'Walking for 60-120 minutes at a moderate pace, in addition to light physical activity associated with typical day-to-day life: or 60 minutes of at least moderate intensity physical activity',
    male: 1.25,
    female: 1.27,
  },
  {
    name: 'Very Active',
    description: 'Walking more than 2 hours at a moderate to brisk pace, in addition to light physical activity associated with typical day-to-day life: or 60 minutes of at least moderate to vigorous intensity physical activity',
    male: 1.48,
    female: 1.45,
  },
]

const patterns = [
  'Good',
  'Average',
  'Poor',
]

const stress = [
  'Low',
  'Medium',
  'High',
]

const minerals = [
  'Energy (kJ)',
  'Protein (g)',
  'Fat (g)',
  'Carbohydrate (g)',
  'Sugar (g)',
  'Fibre (g)',
  'Sodium (g)',
]

const measures = [
  'Cup',
  'Tbsp',
  'tsp',
  'g',
  'kg',
  'Large',
  'Medium',
  'Small',
  'L',
  'ml',
  'Slice(s)',
  'Clove(s)'
]

const nutrients = [
  "EnergyDF_kJ",
  "EnergyDF_Cals",
  "Protein_g",
  "Total_fat_g",
  "Saturated_fat_g",
  "Trans_Fatty_Acids_g",
  "Polyunsaturated_fat_g",
  "Monounsaturated_fat_g",
  "Carbohydrate_available_g",
  "Sugars_g",
  "Dietary_fibre_g",
  "Thiamin_mg",
  "Riboflavin_mg",
  "Niacin_mg",
  "Vitamin_C_mg",
  "Vitamin_B6_by_analysis_mg",
  "Vitamin_B12_g",
  "Total_folate_g",
  "Total_vit_A_eq_g",
  "Sodium_mg",
  "Sodium_mmol",
  "Potassium_mg",
  "Potassium_mmol",
  "Magnesium_mg",
  "Calcium_mg",
  "Phosphorus_mg",
  "Iron_mg",
  "Zinc_mg",
  "Selenium_g",
  "Iodine_g",
  "Kj_from_protein_percent",
  "Kj_from_fat_percent",
  "Kj_from_saturated_fat_percent",
  "Kj_from_carbohydrate_percent",
  "Kj_from_alcohol_percent",
  "VLC_N3_g",
  "Salicylates",
  "Amines",
  "Glutamates",
  "Total_anthocyanins_mg",
  "Cyanidin_mg",
  "Delphinidin_mg",
  "Malvidin_mg",
  "Pelargonidin_mg",
  "Peonidin_mg",
  "Petunidin_mg",
  "Grains_serve",
  "Refined_serve",
  "Wholegrains_serve",
  "Ratio_wholegrains",
  "Fruit_serve",
  "Vegetables_serve",
  "Starchy_vegetables_serve",
  "Legumes_veg_serve",
  "Other_vegetables_serve",
  "Protein_foods_serve",
  "Seafood_high_LC_N3_serve",
  "Nuts_seeds_serve",
  "Legumes_protein_serve",
  "Dairy_serve",
  "Oil_Equivalents_tsp",
  "Energy_from_added_sugar",
  "Energy_from_added_sugar_percent",
  "Alcoholic_drinks_sd",
  "Water_mL",
  "Caffeine_mg"
]

const nutriTagsKeywords = [
  {
    name: "High Protein",
    keywords: [
      "High protein",
      "Protein-rich",
      "Lean meats",
      "Protein shakes",
      "Muscle building",
      "Post-workout",
      "High protein diets",
      "Protein meal plan",
      "Gains",
      "Growth",
      "Bulking",
      "Hypertrophy",
      "Post workout",
    ],
  },
  {
    name: "Mediterranean or Anti-Inflammatory",
    keywords: [
      "Mediterranean diet",
      "Anti-inflammatory",
      "Omega-3",
      "Antioxidants",
      "Polyphenols",
      "Heart-healthy",
      "Low-inflammatory",
      "Whole food",
      "Fish oil",
      "Flavonoids",
      "Fatty fish",
      "No processed foods",
      "Traditional Mediterranean",
      "Mediterranean spices",
      "Anti-inflammatory herbs",
      "Mediterranean cooking",
      "Unprocessed",
      "No added sugar",
      "Antioxidant-rich",
      "Nutrient-dense",
      "Antiinflammatory",
      "Anti-inflamatory",
    ],
  },
  {
    name: "Simple",
    keywords: [
      "Quick",
      "Easy",
      "Simple",
      "Minimalist",
      "Basic",
      "Effortless",
      "Streamlined",
      "Fast",
      "Convenient",
      "Minimal",
      "Short",
      "Speedy",
      "Basic ingredients",
      "Straightforward",
      "Uncomplicated",
      "Quick-fix",
      "Snappy",
      "No-fuss",
      "Hassle-free",
      "Beginner",
      "Speed-cooking",
      "Low-ingredient",
      "Five-ingredient",
      "Six-ingredient",
      "Quick meals",
      "Easy prep",
      "Fast food",
      "Quick dinner",
      "Simple cooking",
      "Express",
      "Time-saver",
      "Efficient",
      "Instant",
      "Rapid",
      "Breezy",
      "Lazy meal",
      "Minimal cooking",
      "Fast prep",
      "Short-list ingredients",
      "Four-ingredient",
      "Easy recipes",
      "Basic cooking",
      "4 Ingredient",
      "5 ingredient",
      "6 ingredient",
      "No fuss",
      "Hassle fee",
      "Low ingredient",
      "Five ingredient",
      "Six ingredient",
      "Quick fix",
      "No fuss",
      "Speed cooking",
      "Low ingredient",
      "Five ingredient",
      "Six ingredient",
      "Time saver",
      "Short list ingredients",
      "Four ingredient",
    ],
  },
  {
    name: "Low Carb",
    keywords: [
      "Low carb",
      "Sugar-free",
      "No sugar added",
      "Lower carbohydrate",
      "Little carb",
      "Minimal carb",
      "Diabetes friendly",
      "Post-prandial",
      "Sugar free",
      "Post prandial",
    ],
  },
  {
    name: "Gluten Free",
    keywords: [
      "Gluten Free",
      "Gluten-free",
      "Celiac safe",
      "Gluten intolerance",
      "Gluten-free flour",
      "Coeliac safe",
      "Non-gluten",
      "Glutenless",
      "Gluten-less",
      "Free of gluten",
      "No gluten",
      "No-gluten",
    ],
  },
  {
    name: "Vegetarian Friendly",
    keywords: [
      "Vegetarian",
      "Meatless",
      "Meat substitutes",
      "meat free",
      "meat-free",
      "meat less",
      "no meat",
    ],
  },
  {
    name: "High Fibre",
    keywords: [
      "High fibre",
      "Dietary fibre",
      "Psyllium husk",
      "Fibre-rich recipes",
      "Soluble fibre",
      "Insoluble fibre",
      "Fibre for digestion",
      "Healthy gut",
      "Prebiotic fibre",
      "Fibre meal plan",
      "Constipation",
      "High fiber",
      "Dietary fiber",
      "Psyllium husk",
      "fiber-rich recipes",
      "Soluble fiber",
      "Insoluble fiber",
      "fiber for digestion",
      "Healthy gut",
      "Prebiotic fiber",
      "Nutrient-dense fiber",
      "fiber meal plan",
      "Nutrient-dense fibre",
    ],
  },
  {
    name: "Low Lactose",
    keywords: [
      "Low lactose",
      "Lactose intolerance",
      "Lactose-free",
      "Lactase enzyme",
      "Lactose digestion",
      "Lower lactose",
      "Lactose free",
      "No lactose",
    ],
  },
  {
    name: "Low Fat",
    keywords: [
      "Low fat",
      "Reduced fat",
      "Low-fat dairy",
      "Skim milk",
      "Fat-free",
      "Low-saturated fat",
      "Trans fat-free",
      "Unsaturated fats",
      "Lower fat",
      "Little fat",
      "Minimal fat",
      "Semi-skimmed",
      "Low fat dairy",
      "Fat free",
      "Low saturated fat",
      "Semi skimmed",
    ],
  },
  {
    name: "Dairy Free",
    keywords: [
      "Dairy-free",
      "Non-dairy",
      "Milk-free",
      "Dairy substitute",
      "Casein-free",
      "Whey-free",
      "Dairy alternatives",
      "Dairy free",
      "Non dairy",
      "Milk free",
      "Casein free",
      "Whey free",
    ],
  },
  {
    name: "Pescatarian Friendly",
    keywords: [
      "Pescatarian",
      "Seafood",
      "Fish",
      "Sustainable seafood",
      "Pescatarian recipes",
      "Flexitarian",
    ],
  },
  {
    name: "Low FODMAP",
    keywords: [
      "Low FODMAP",
      "IBS friendly",
      "Fructose-friendly",
      "Monash University",
      "FODMAP diet",
      "FODMAP recipes",
      "Irritable Bowel Syndrome",
      "Gastrointestinal relief",
    ],
  },
  {
    name: "Vegan Friendly",
    keywords: [
      "Vegan",
      "Plant-based",
      "Animal-free",
      "Meat substitutes",
      "Cruelty-free",
    ],
  },
  {
    name: "Nut Free",
    keywords: [
      "Nut free",
      "Tree nut allergy",
      "Peanut allergy",
      "Nut-free recipes",
      "Nut-free ",
    ],
  },
  {
    name: "Low GI",
    keywords: [
      "Low GI",
      "Glycemic index",
      "Diabetes friendly",
      "Insulin resistance",
      "Stable energy",
      "Slow-release carbs",
      "Blood sugar management",
      "Low GI lifestyle",
      "Slow release carbs",
    ],
  },
  {
    name: "Keto Friendly",
    keywords: [
      "Keto",
      "Ketogenic",
      "High fat",
      "Keto diet plan",
      "Ketosis",
      "Keto recipes",
      "Keto meal prep",
      "Atkins",
    ],
  },
  {
    name: "Low Sodium",
    keywords: [
      "Low sodium",
      "Sodium-free",
      "Reduced sodium",
      "Blood pressure",
      "Hypertension",
      "Salt-free",
      "No added salt",
      "Unsalted",
      "Salt substitutes",
      "Diuretic foods",
      "Managing sodium intake",
      "Sodium free",
      "Salt free",
    ],
  },
  {
    name: "Large Serve",
    keywords: [
      "Large serve",
      "Big portions",
      "Generous servings",
      "Double recipe",
      "Large salads",
      "Sizable servings",
      "Voluminous",
      "Ample portions",
      "Jumbo",
      "Feast",
      "King-size",
      "Super-size",
      "Large scale",
      "Substantial",
      "Hefty portions",
      "Massive servings",
      "Gargantuan dishes",
    ],
  },
  {
    name: "Seafood Free",
    keywords: [
      "Seafood free",
      "Fish-free",
      "Shellfish-free",
      "Seafood allergy",
      "Fish allergy",
      "Shellfish allergy",
      "Fish free",
      "Shellfish",
      "Shellfish free",
      "No fish",
      "No shellfish",
    ],
  },
  {
    name: "Paleo Friendly",
    keywords: [
      "Paleo",
      "Grain-free",
      "Legume-free",
      "Natural foods",
      "Whole foods",
      "Caveman diet",
      "Hunter-gatherer diet",
      "Primal diet",
      "Grass-fed",
      "Free-range",
      "Wild-caught fish",
      "Paleo-friendly",
      "Clean eating",
      "Autoimmune Paleo",
      "Whole30",
    ],
  },
  {
    name: "Egg Free",
    keywords: [
      "Egg-free",
      "No eggs",
      "Egg allergy",
      "Egg substitute",
      "Eggless",
      "Egg Free",
    ],
  },
  {
    name: "Low Energy Density",
    keywords: [
      "Low energy density",
      "Low calorie",
      "High volume",
      "Nutrient-dense",
      "Water-rich",
      "Satiety",
      "Mindful eating",
      "Weight loss",
      "Healthy lifestyle",
    ],
  },
  {
    name: "Wheat Free",
    keywords: ["Wheat free", "Wheat allergy", "Wheat sensitivity"],
  },
  {
    name: "High Carb",
    keywords: [
      "High carb",
      "Carbohydrates",
      "High carbohydrates",
      "Carb loading",
      "Carb-rich",
      "Sports nutrition",
      "Glycogen",
      "Pre-workout meals",
      "Post-workout meals",
    ],
  },
  {
    name: "Low Histamine",
    keywords: [
      "Low histamine",
      "Histamine intolerance",
      "Histamine-free",
      "Low histamine diet",
      "Histamine-reduced",
      "Histamine intolerance relief",
      "Low histamine recipes",
      "Histamine-sensitive",
      "Histamine reaction",
      "Antihistamine foods",
      "Histamine detox",
      "Histamine-free cooking",
      "Histamine intolerance management",
      "Histamine reducing",
      "Histamine free",
      "Histamine reduced",
      "Histamine sensitive",
      "Histamine free cooking",
    ],
  },
  {
    name: "Medium Serve",
    keywords: [
      "Medium serve",
      "Average serving size",
      "Standard portion",
      "Regular serving",
      "Moderate eating",
      "Standard meal size",
      "Moderation in eating",
      "Controlled portions",
    ],
  },
  {
    name: "Halal Friendly",
    keywords: [
      "Halal",
      "Permissible",
      "Zabihah",
      "Islamic diet",
      "Muslim-friendly",
      "Shariah compliant",
      "No alcohol",
      "No pork",
      "Halal-certified",
      "Pure",
      "Ethical meat",
      "Faith-based diet",
      "Spiritual eating",
      "Islamic cooking",
      "Muslim meals",
      "Shariah diet",
      "Ethical eating",
      "Halal certification",
      "Islamic cuisine",
      "Prayerful eating",
      "Ramadan recipes",
      "Eid meals",
      "Islamic fasting",
      "Muslim cooking",
      "Islamic meals",
    ],
  },
  {
    name: "Soy Free",
    keywords: [
      "Soy free",
      "Soybean free",
      "Soy allergy",
      "Soybean allergy",
      "Non-soy protein",
    ],
  },
  {
    name: "High Energy Density",
    keywords: [
      "Energy foods",
      "High-energy meals",
      "High energy density",
      "Calorie-dense",
      "kilojoule dense",
      "kilojoule rich",
      "High kilojoule",
      "High-kilojoule",
    ],
  },
  {
    name: "Low Protein",
    keywords: [
      "Low protein",
      "Reduced protein",
      "Low protein diet",
      "Protein restriction",
      "PKU",
      "Phenylketonuria",
      "Low-protein diet",
      "Restricted protein",
    ],
  },
  {
    name: "Small Serve",
    keywords: [
      "Small serve",
      "Petite portion",
      "Mini portion",
      "Small plate",
      "Small meal",
      "Small portion",
      "Miniature serving",
      "Small plate dining",
      "Tiny portion",
      "Compact serving",
      "Smaller serving size",
      "Delicate portion",
      "Diminutive serving",
      "Dainty portion",
      "Light meal",
      "Lighter portion",
      "Small-scale meal",
      "Compact meal",
      "Mini meal",
      "Light fare",
      "Petite meal",
      "Small dish",
      "Petite plate",
      "Trim portion",
      "Slim serving",
      "Slight portion",
      "Bite-sized meal",
      "Wee portion",
      "Small portion strategy",
      "Scaled-down serving",
      "Bariatric",
      "surgery",
      "Gastric bypass",
      "Gastric sleeve",
      "Bariatric-friendly",
      "Post-bariatric surgery diet",
      "Bariatric meal plan",
      "Bariatric nutrition",
      "Bariatric surgery",
      "Bariatric-friendly",
      "Small serves",
      "Small plate",
      "Small meal",
      "Small portion",
      "Small plate dining",
      "Bariatric meal plan",
      "Bariatric nutrition",
      "Bariatric surgery",
      "Bariatric friendly",
    ],
  },
]

export {
  goals,
  frequencies,
  levels,
  patterns,
  stress,
  minerals,
  measures,
  nutrients,
  nutriTagsKeywords
}

export default mealTime
